export const CALCULATE_CUSTOMER_POSITION = 'CALCULATE_CUSTOMER_POSITION'
export const HIDE_PRELOADER = 'HIDE_PRELOADER'
export const LOAD_LAST_ADDRESS = 'LOAD_LAST_ADDRESS'
export const RELOAD_BRANCHES = 'RELOAD_BRANCHES'
export const RELOAD_LOCALITIES = 'RELOAD_LOCALITIES'
export const RELOAD_PRODUCTS = 'RELOAD_PRODUCTS'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SET_BRANCH = 'SET_BRANCH'
export const SET_BRANCHES = 'SET_BRANCHES'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_CUSTOMER_ADDRESS = 'SET_CUSTOMER_ADDRESS'
export const SET_SECTION_AND_PLACE_CODE = 'SET_SECTION_AND_PLACE_CODE'
export const SET_LOCALITY = 'SET_LOCALITY'
export const SET_LOCALITIES = 'SET_LOCALITIES'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_POSITION = 'SET_POSITION'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SHOW_PRELOADER = 'SHOW_PRELOADER'
export const UPDATE_SIGNED_CUSTOMER = 'UPDATE_SIGNED_CUSTOMER'
