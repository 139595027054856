import React from 'react';
import { Headline3 } from '@material/react-typography';
import CategoryList from '../Categories/List';
import '../../styles/sidebar.css';

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-menu d-none d-sm-block">
        <Headline3 className="mt-1 mb-3">Categorías</Headline3>
        <CategoryList className="mdc-card p-1" />
      </div>
    </div>
  );
}

export default Sidebar;
