const cutText = function (text, maxLength = 80) {
  let cutText = text;
  if (text.length > maxLength) {
    cutText = text.slice(0, maxLength) + '...';
  }

  return cutText;
}

export { cutText };
