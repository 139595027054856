import React from 'react'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const CategoryList = ({ dispatch, categories, selectedCategory, className }) => {
  const selectCategory = (index) => {
    dispatch({ type: 'SHOW_PRELOADER' })
    dispatch({ type: 'SET_CATEGORY', categoryIndex: index, dispatch: dispatch })
  }

  return (
    <List component='nav' aria-label='categorías' className={className}>
      {
        categories.map((category, i) => {
          return (
            <ListItem
              button
              selected={selectedCategory === i}
              onClick={(e) => selectCategory(i)}
              key={`${i}-${category.id}`}
            >
              <ListItemText primary={category.name} />
            </ListItem>
          )
        })
      }
    </List>
  )
}

const mapStateToProps = (state) => ({
  categories: state.main.categories,
  selectedCategory: state.main.selectedCategory
})
export default connect(mapStateToProps)(CategoryList)
