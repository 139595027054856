function convertToCurrency(val) {
  if (!val) return '$ 0'
  let isNegative = false
  let str = val.toString().trim()
  let base = str
  if (str.charAt(0) === '-') {
    base = str.substr(1, str.length).trim()
    isNegative = true
  }
  let splitedVal = base.split('.')
  let reversedVal = splitedVal[0].split('').reverse()
  let formattedVal = ''
  reversedVal.forEach((c, i) => {
    formattedVal += c + ((i + 1) % 3 === 0 && reversedVal[i + 1] !== undefined ? '.' : '')
  })
  return `$ ${isNegative ? '-' : ''}` + (splitedVal[1] !== undefined && parseInt(splitedVal[1]) > 0 ? `${formattedVal.split('').reverse().join('')},${splitedVal[1].slice(0, 2)}` : formattedVal.split('').reverse().join(''))
}

function convertToNumber(val) {
  if (!val) return 0
  return typeof val === 'string' ? parseFloat(val.replace(/[\\$.]/g, '').replace(',', '.')) : typeof val === 'number' ? val : 0
}

export {
  convertToCurrency,
  convertToNumber
}
