import { HIDE_PRELOADER, LOAD_LAST_ADDRESS, SET_CUSTOMER_ADDRESS, UPDATE_SIGNED_CUSTOMER } from '../redux/actionTypes'
import WemeikAPI from '../services/wemeik-api'

const CUSTOMER_KEY = 'wemeikCustomer'

function b64DecodeUnicode(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const Auth = {
  setCurrentCustomer(context, customer) {
    context.props.dispatch({ type: 'updateCustomer', customer: customer })
  },

  login(context, credentials, redirect = null) {
    WemeikAPI.customers.login(context.$http, credentials)
      .then((response) => {
        this.authenticateCustomer(context, response.token)

        context.dispatch({ type: LOAD_LAST_ADDRESS, dispatch: context.dispatch })

        if (redirect) {
          context.history.push(redirect)
        }
      })
      .catch((error) => {
        console.error('Error logging in as customer: ', error)
        context.dispatch({ type: 'openSnackbar', snackbar: { message: 'No se ha podido iniciar sesión.', type: 'error' } })
      })
      .finally(() => {
        context.dispatch({ type: HIDE_PRELOADER })
      })
  },

  authenticateCustomer(context, token) {
    if (token) {
      localStorage.setItem(CUSTOMER_KEY, token)

      this.updateSignedCustomer(context)
    } else {
      this.logoutWithoutRedirection(context)
    }
  },

  updateSignedCustomer(context) {
    const token = localStorage.getItem(CUSTOMER_KEY)

    if (token) {
      const authenticatedHttp = context.$http

      context.dispatch({ type: UPDATE_SIGNED_CUSTOMER, signedCustomer: this.getCustomerInfo() })

      authenticatedHttp.defaults.headers.Authorization = this.getAuthToken()
      context.dispatch({ type: 'updateHttpConnector', $http: authenticatedHttp })
    }
  },

  logout(context) {
    this.logoutWithoutRedirection(context)
    context.history.push('/')
  },

  logoutWithoutRedirection(context) {
    if (this.getCustomerInfo().scp === 'customer') {
      WemeikAPI.customers.logout(context.$http)
        .then((response) => {})
        .catch((error) => {
          console.error('Error logging out as customer: ', error)
        })
        .finally(() => {
          context.dispatch({ type: HIDE_PRELOADER })
        })
    }

    localStorage.removeItem(CUSTOMER_KEY)
    context.dispatch({ type: UPDATE_SIGNED_CUSTOMER, signedCustomer: { authenticated: false } })
    context.dispatch({ type: SET_CUSTOMER_ADDRESS, customerAddress: {} })
    context.dispatch({ type: 'openSnackbar', snackbar: { message: 'Gracias, ¡vuelve pronto!' } })

    if (context.$http) {
      try {
        const noAuthenticatedHttp = context.$http
        delete noAuthenticatedHttp.defaults.headers.Authorization
        context.dispatch({ type: 'updateHttpConnector', $http: noAuthenticatedHttp })
      } catch (error) {
        console.error('Error logging out: ', error)
      }
    }
  },

  isAuthenticated(context) {
    const token = localStorage.getItem(CUSTOMER_KEY)

    if (token) {
      return true
    } else if (context.$http) {
      const noAuthenticatedHttp = context.$http
      delete noAuthenticatedHttp.defaults.headers.Authorization
      context.dispatch({ type: 'updateHttpConnector', $http: noAuthenticatedHttp })
      context.dispatch({ type: UPDATE_SIGNED_CUSTOMER, signedCustomer: { authenticated: false } })
    }

    return false
  },

  getAuthToken() {
    const token = localStorage.getItem(CUSTOMER_KEY)

    if (token) {
      return `Bearer ${token}`
    }

    return null
  },

  getCustomerInfo(token = null) {
    const currentToken = token ? token : localStorage.getItem(CUSTOMER_KEY)
    return currentToken ? JSON.parse(b64DecodeUnicode(currentToken)) : {}
  }
}

export default Auth
