import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/campaign.css';
import IconButton from '@material/react-icon-button';
import MaterialIcon from '@material/react-material-icon';
import WemeikAPI from '../../services/wemeik-api';

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCampaign: true,
      campaign: {}
    }

    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.goToCampaign = this.goToCampaign.bind(this);
  }

  componentDidMount() {
    this.loadCampaign();
  }

  loadCampaign() {
    WemeikAPI.companies.activeCampaign(this.props.$http, this.props.companyId)
      .then((campaign) => {
        if (campaign.constructor === Object || Object.keys(campaign).length > 0) {
          this.setState({ campaign: campaign });
        }
      })
      .catch((error) => {
        console.error('Error loading active campaign: ', error)
      });
  }

  hide() {
    this.setState({ showCampaign: false });
  }

  handleHide(e) {
    this.hide();
  }

  handleClick(e) {
    const target = e.target;
    if (target.tagName === 'DIV') {
      this.hide();
    }
  }

  goToCampaign(e) {
    WemeikAPI.campaigns.click(this.props.$http, this.props.companyId, this.state.campaign.id, this.props.customer.sub)
      .catch((error) => {
        console.error('Error: ', error);
      });

    const urlParts = this.state.campaign.link.split('/');
    const nParts = urlParts.length;
    if (this.state.campaign.link.length <= 1) {
      this.hide();
    } else if (nParts > 2 && window.location.host === urlParts[2]) {
      let route = '/';
      for(let i = 3; i < nParts; i++) {
        route += urlParts[i] + '/';
      }
      this.props.history.push(route);
      this.hide();
    } else {
      window.open(this.state.campaign.link, '_blank');
    }
  }

  render() {
    if (this.state.showCampaign && (this.state.campaign && this.state.campaign.banner_url)) {
      return ReactDOM.createPortal(
        <div onClick={this.handleClick} className="banner-overlay">
          <div className="banner-container">
            <div className="banner-image">
              <IconButton onClick={this.handleHide} className="hide-button" title="Cerrar">
                <MaterialIcon icon='close' />
              </IconButton>
              <img onClick={this.goToCampaign} src={this.state.campaign.banner_url} alt="" />
            </div>
            <a className="mdc-typography mdc-typography--headline5 text-light mt-1 mb-3" href={this.state.campaign.terms_and_conditions} target="_blank" rel="noopener noreferrer">Terminos y Condiciones</a>
          </div>
        </div>
        , document.getElementById('modal')
      );
    }

    return null;
  };
}

const mapStateToProps = (state) => ({
  $http: state.main.$http,
  companyId: state.main.companyId,
  customer: state.main.signedCustomer
});
export default connect(mapStateToProps)(withRouter(Campaign));
