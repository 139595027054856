import Auth from './auth'
import axios from 'axios'

export default function http(opts = {}) {
  return axios.create(Object.assign({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
      Authorization: Auth.getAuthToken()
    }
  }, opts))
}
