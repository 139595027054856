import Db from '../../utils/db'
import { CALCULATE_CUSTOMER_POSITION, HIDE_PRELOADER, LOAD_LAST_ADDRESS, RELOAD_BRANCHES, RELOAD_LOCALITIES, RELOAD_PRODUCTS, SEARCH_PRODUCTS, SET_CUSTOMER_ADDRESS, SET_BRANCH, SET_BRANCHES, SET_CATEGORY, SET_CATEGORIES, SET_COMPANY, SET_LOCALITY, SET_LOCALITIES, SET_POSITION, SET_PRODUCTS, SHOW_PRELOADER, UPDATE_SIGNED_CUSTOMER } from '../actionTypes'
import http from '../../utils/http'
import { getSessionStorageValue, setSessionStorageValue } from '../../utils/util'
import { calculateCustomerLocation, loadLastAddress, loadBranches, loadLocalities, loadProducts, showAlert } from '../functions'

// Initial State
const initialState = {
  $http: http(),
  branches: [],
  categories: [],
  appUrl: process.env.REACT_APP_URL,
  companyId: process.env.REACT_APP_COMPANY_ID,
  company: {},
  countSelectedProducts: 0,
  Db: null,
  drawerIsOpened: false,
  isLoading: false,
  localities: [],
  productIndex: 0,
  products: [],
  searchTerm: '',
  selectedCategory: 0,
  customerAddress: {},
  selectedLocalityId: null,
  selectedBranch: {},
  selectedProducts: [],
  signedCustomer: {},
  snackbar: null,
  companyName: '',
  position: JSON.parse(getSessionStorageValue('position', '{}'))
}

// Reducer Declaration
const mainReducer = (state = initialState, action) => {
  let newState = {}
  let selectedProducts = []
  let productIndex = 0
  switch (action.type) {
    case 'LoadCartFromDb':
      newState.selectedProducts = action.selectedProducts
      newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0)

      productIndex = 0
      newState.selectedProducts.forEach((p) => {
        if (p.pId > productIndex) {
          productIndex = p.pId
        }
      })

      newState.productIndex = productIndex
      break
    case 'addItemToCart':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length)
      newState.productIndex = state.productIndex + 1
      action.product.pId = newState.productIndex
      const product = Object.assign({}, action.product)

      newState.selectedProducts = selectedProducts.concat([product])
      newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + parseFloat(p.quantity), 0)

      Db.saveProduct(product)
      break
    case 'removeItemFromCart':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length)

      productIndex = -1
      selectedProducts.forEach((p, i) => {
        if (p.pId === action.product.pId) {
          productIndex = i
        }
      })

      if (productIndex >= 0) {
        Db.db.selectedProducts.where({ pId: action.product.pId }).delete()

        selectedProducts.splice(productIndex, 1)
        newState.selectedProducts = selectedProducts
        newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0)
      }
      break
    case 'emptyCart':
      state.selectedProducts.forEach((p) => {
        Db.db.selectedProducts.where({ pId: p.pId }).delete()
      })

      newState.selectedProducts = []
      newState.countSelectedProducts = 0
      break
    case 'updateProduct':
      selectedProducts = state.selectedProducts.slice(0, state.selectedProducts.length)

      productIndex = -1
      selectedProducts.forEach((p, i) => {
        if (p.pId === action.product.pId) {
          productIndex = i
        }
      })

      if (productIndex >= 0) {
        selectedProducts[productIndex] = action.product

        newState.selectedProducts = selectedProducts

        Db.updateProduct(action.product)
        newState.countSelectedProducts = newState.selectedProducts.reduce((tq, p) => tq + p.quantity, 0)
      }
      break
    case 'closeDrawer':
      newState.drawerIsOpened = false
      break
    case 'openDrawer':
      newState.drawerIsOpened = true
      break
    case 'openSnackbar':
      if (state.snackbar && action.snackbar) {
        showAlert(state.snackbar, action.snackbar.message, action.snackbar.type)
      }
      break
    case 'setSnackbar':
      newState.snackbar = action.snackbar
      break
    case LOAD_LAST_ADDRESS:
      loadLastAddress(action.dispatch, state.$http)
      break
    case SET_CUSTOMER_ADDRESS:
      newState.customerAddress = action.customerAddress
      break
    case SET_BRANCH:
      newState.selectedBranch = action.selectedBranch

      if (Array.isArray(state.categories) && state.categories.length > state.selectedCategory) {
        const category = state.categories[state.selectedCategory]
        newState.searchTerm = ''

        loadProducts(action.dispatch, state.$http, { category_id: category.id, branch_id: newState.selectedBranch.id })
      } else {
        newState.isLoading = false
      }
      break
    case SET_BRANCHES:
      newState.branches = action.branches
      break
    case SET_LOCALITIES:
      newState.localities = action.localities
      break
    case SET_LOCALITY:
      newState.selectedLocalityId = action.localityId
      setSessionStorageValue('localityId', newState.selectedLocalityId)
      if (newState.selectedLocalityId !== null && newState.selectedLocalityId > 0 && newState.selectedLocalityId !== state.selectedLocalityId) {
        loadBranches(action.dispatch, state.$http, newState.selectedLocalityId, state.position)
      } else {
        newState.isLoading = false
      }
      break
    case SET_CATEGORIES:
      newState.categories = action.categories
      break
    case SET_CATEGORY:
      newState.selectedCategory = action.categoryIndex
      newState.searchTerm = ''

      if (Array.isArray(state.categories) && state.categories.length > newState.selectedCategory) {
        const category = state.categories[newState.selectedCategory]

        loadProducts(action.dispatch, state.$http, { category_id: category.id })
      } else {
        newState.isLoading = false
      }
      break
    case SET_COMPANY:
      newState.company = action.company
      if (typeof newState.company === 'object' && newState.company.long_name !== undefined) {
        newState.companyName = newState.company.long_name
      }
      break
    case CALCULATE_CUSTOMER_POSITION:
      calculateCustomerLocation(action.dispatch)
      break
    case SET_PRODUCTS:
      newState.products = action.products.sort((a, b) => (a.name > b.name) ? 1 : -1)
      break
    case SEARCH_PRODUCTS:
      newState.searchTerm = action.searchTerm

      newState.isLoading = true
      if (action.searchTerm === '') {
        const category = state.categories[state.selectedCategory ? state.selectedCategory : 0]

        if (typeof category === 'object' && category.id !== undefined) {
          loadProducts(action.dispatch, state.$http, { category_id: category.id })
          break
        }
      }

      loadProducts(action.dispatch, state.$http, { search: newState.searchTerm })
      break
    case SHOW_PRELOADER:
      newState.isLoading = true
      break
    case HIDE_PRELOADER:
      newState.isLoading = false
      break
    case 'updateHttpConnector':
      newState.$http = action.$http
      break
    case RELOAD_LOCALITIES:
      loadLocalities(action.dispatch, state.$http, state.position)
      break
    case RELOAD_PRODUCTS:
      if (state.categories && state.categories.length > state.selectedCategory) {
        const category = state.categories[state.selectedCategory]

        if (typeof category === 'object' && category.id !== undefined) {
          loadProducts(action.dispatch, state.$http, { category_id: category.id })
        } else {
          loadProducts(action.dispatch, state.$http, { search: state.searchTerm })
        }
      }
      break
    case 'setIndexedDb':
      newState.Db = action.db
      break
    case SET_POSITION:
      newState.position = action.position
      setSessionStorageValue('position', JSON.stringify(action.position))
      loadLocalities(action.dispatch, state.$http, newState.position)
      break
    case RELOAD_BRANCHES:
      if (state.localities && state.selectedLocalityId !== undefined && state.selectedLocalityId > 0) {
        loadBranches(action.dispatch, state.$http, newState.selectedLocality, state.position)
      } else {
        loadLocalities(action.dispatch, state.$http, newState.position)
      }
      break
    case UPDATE_SIGNED_CUSTOMER:
      newState.signedCustomer = Object.assign({}, state.signedCustomer, action.signedCustomer)
      break
    default:
      return state
  }

  return Object.assign({}, state, newState)
}

export default mainReducer
