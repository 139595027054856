import { ANONYMOUS_CUSTOMERS_URL, COMPANY_LOCALITIES_URL, CUSTOMERS_URL, CUSTOMER_URL, ADDRESSES_URL, ORDERS_URL, COMPANIES_URL, BRANCHES_URL, CATEGORIES_URL, PRODUCTS_URL, LOCALITIES_URL, LIST_ITEMS_URL, CAMPAIGNS_URL, ACCOMPANIMENTS_URL, FCM_URL, SHOW_BRANCH_URL, SECTIONS_URL, PLACES_URL, RESERVATIONS_URL } from './wemeik-api-routes'

const getData = (response) => response.data

const WemeikAPI = {
  accompaniments: {
    index(http, companyId) {
      return http.get(ACCOMPANIMENTS_URL.replace(/:company_id/i, companyId)).then(getData)
    }
  },
  addresses: {
    index(http) {
      return http.get(ADDRESSES_URL).then(getData)
    },
    show(http, addressId) {
      return http.get(ADDRESSES_URL + '/' + addressId).then(getData)
    },
    create(http, addressParams, companyId = null) {
      return http.post(ADDRESSES_URL, { address: addressParams, company_id: companyId }).then(getData)
    },
    update(http, addressId, addressParams, companyId = null) {
      return http.put(ADDRESSES_URL + '/' + addressId, { address: addressParams, company_id: companyId }).then(getData)
    },
    delete(http, addressId) {
      return http.delete(ADDRESSES_URL + '/' + addressId).then(getData)
    },
    showLast(http) {
      return http.get(CUSTOMER_URL + '/last_updated_address').then(getData)
    }
  },
  anonymousCustomers: {
    create(http, anonymousCustomerParams) {
      return http.post(ANONYMOUS_CUSTOMERS_URL, { anonymous_customer: anonymousCustomerParams }).then(getData)
    }
  },
  branches: {
    index(http, companyId, localityId, params = {}) {
      return http.get(BRANCHES_URL.replace(/:company_id/i, companyId).replace(/:locality_id/i, localityId), { params: params }).then(getData)
    },
    show(http, companyId, branchId) {
      return http.get(SHOW_BRANCH_URL.replace(/:company_id/i, companyId) + '/' + branchId).then(getData)
    }
  },
  campaigns: {
    click(http, companyId, campaignId, customerId = null) {
      return http.post(CAMPAIGNS_URL.replace(/:company_id/i, companyId) + '/' + campaignId + '/click', { customer_id: customerId }).then(getData)
    }
  },
  categories: {
    index(http, companyId) {
      return http.get(CATEGORIES_URL.replace(/:company_id/i, companyId)).then(getData)
    }
  },
  companies: {
    activeCampaign(http, companyId) {
      return http.get(COMPANIES_URL + '/' + companyId + '/active_campaign').then(getData)
    },
    show(http, companyId) {
      return http.get(COMPANIES_URL + '/' + companyId).then(getData)
    }
  },
  customers: {
    show(http) {
      return http.get(CUSTOMER_URL).then(getData)
    },
    create(http, customerParams) {
      return http.post(CUSTOMERS_URL, { customer: customerParams }).then(getData)
    },
    update(http, customerParams) {
      return http.put(CUSTOMERS_URL, { customer: customerParams }).then(getData)
    },
    login(http, credentials) {
      return http.post(`${CUSTOMERS_URL}/sign_in`, { customer: credentials }).then(getData)
    },
    logout(http) {
      return http.delete(`${CUSTOMERS_URL}/sign_out`).then((response) => response)
    }
  },
  fcm: {
    create(http, token) {
      return http.post(FCM_URL, { fcm_token: { token: token } }).then(getData)
    }
  },
  listItems: {
    index(http, listName) {
      return http.get(LIST_ITEMS_URL + '/' + listName).then(getData)
    }
  },
  localities: {
    index(http, params = {}) {
      return http.get(LOCALITIES_URL, { params: params }).then(getData)
    },
    nearest(http, companyId, params = {}) {
      return http.get(COMPANY_LOCALITIES_URL.replace(/:company_id/i, companyId) + '/nearest', { params: params }).then(getData)
    }
  },
  orders: {
    activeOrderNow(http, companyId) {
      return http.get(ORDERS_URL + '/active_order_now', { params: { company_id: companyId } }).then(getData)
    },
    index(http, companyId) {
      return http.get(ORDERS_URL, { params: { company_id: companyId } }).then(getData)
    },
    show(http, orderId) {
      return http.get(ORDERS_URL + '/' + orderId).then(getData)
    },
    status(http, orderId) {
      return http.get(ORDERS_URL + '/' + orderId + '/status').then(getData)
    },
    create(http, orderParams, companyId) {
      return http.post(ORDERS_URL, { order: orderParams, company_id: companyId }).then(getData)
    },
    cancel(http, orderId) {
      return http.put(ORDERS_URL + '/' + orderId + '/cancel').then(getData)
    }
  },
  products: {
    index(http, companyId, params = {}) {
      return http.get(PRODUCTS_URL.replace(/:company_id/i, companyId), { params: params }).then(getData)
    },
    show(http, companyId, productId) {
      return http.get(PRODUCTS_URL.replace(/:company_id/i, companyId) + '/' + productId).then(getData)
    }
  },
  places: {
    index(http, sectionId, params = {}) {
      return http.get(PLACES_URL.replace(/:section_id/i, sectionId), { params: params }).then(getData)
    }
  },
  reservations: {
    index(http, companyId, params = {}) {
      return http.get(RESERVATIONS_URL.replace(/:company_id/i, companyId), { params: params }).then(getData)
    },
    show(http, companyId, reservationId) {
      return http.get(RESERVATIONS_URL.replace(/:company_id/i, companyId) + '/' + reservationId).then(getData)
    },
    status(http, companyId, reservationId) {
      return http.get(RESERVATIONS_URL.replace(/:company_id/i, companyId) + '/' + reservationId + '/status').then(getData)
    },
    create(http, companyId, reservationParams) {
      return http.post(RESERVATIONS_URL.replace(/:company_id/i, companyId), { reservation: reservationParams }).then(getData)
    },
    cancel(http, companyId, reservationId, params = {}) {
      return http.put(RESERVATIONS_URL.replace(/:company_id/i, companyId) + '/' + reservationId + '/cancel', params).then(getData)
    }
  },
  sections: {
    index(http, branchId, params = {}) {
      return http.get(SECTIONS_URL.replace(/:branch_id/i, branchId), { params: params }).then(getData)
    }
  }
}

export default WemeikAPI
