import Dexie from 'dexie';

// Functions
class Db {
  constructor() {
    this.con = new Dexie('UOrderDB');
    // Declare tables, IDs and indexes
    this.con.version(1).stores({
      selectedProducts: '++id, pId, productId, name, description, price, total, image, observations, additionals'
    });
  }

  get db() {
    return this.con;
  }

  loadProducts(store) {
    this.con.selectedProducts.toArray().then((products) => {
      store.dispatch({ type: 'LoadCartFromDb', selectedProducts: products });
    });
  }

  saveProduct(product) {
    this.con.selectedProducts.add({
      pId: product.pId,
      productId: product.productId,
      name: product.name,
      description: product.description,
      presentation: product.presentation,
      fractionId: product.fractionId,
      quantity: parseFloat(product.quantity),
      price: parseFloat(product.price),
      total: parseFloat(product.total),
      observations: product.observations,
      additionals: product.additionals,
      fractions: product.fractions,
      image_url: product.image_url,
      min_additionals: product.min_additionals,
      max_additionals: product.max_additionals
    }).then((newId) => { product.id = newId; });
  }

  updateProduct(product) {
    this.con.selectedProducts.update(product.id, {
      presentation: product.presentation,
      fractionId: product.fractionId,
      quantity: parseFloat(product.quantity),
      price: parseFloat(product.price),
      total: parseFloat(product.total),
      observations: product.observations,
      additionals: product.additionals
    });
  }
}

const MyDb = new Db();

export default MyDb;
