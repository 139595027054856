import Db from '../utils/db'
import Auth from '../utils/auth'
import http from '../utils/http'
import { createStore } from 'redux'
import rootReducer from './reducers'
import { loadCompanyInfo, loadCategories } from './functions'

const store = createStore(rootReducer)

//------- Initializers --------//
loadCompanyInfo(store, store.getState().main.$http)
loadCategories(store, store.getState().main.$http)
// Load initial Data from IndexedDB
Db.loadProducts(store)

store.dispatch({ type: 'setIndexedDb', db: Db })

// HTTP request proxy
store.dispatch({
  type: 'updateHttpConnector',
  $http: http({
    validateStatus(status) {
      if (status === 401) {
        Auth.logoutWithoutRedirection({ dispatch: store.dispatch, history: {}, ...store.getState().main })
      }
      return status >= 200 && status < 300
    }
  })
})

Auth.updateSignedCustomer({ dispatch: store.dispatch, ...store.getState().main })

export default store
