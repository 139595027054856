import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/products.css';
import Product from './Chunk';
import { Headline3, Headline4, Body1 } from '@material/react-typography';
import WemeikAPI from '../../services/wemeik-api.js';

class ProductList extends React.Component {
  componentDidMount() {
    if (!this.props.products || this.props.products.length < 1) {
      this.props.dispatch({ type: 'SHOW_PRELOADER' });
      if (!this.props.categories || this.props.categories.length < 1) {
        this.loadCategories();
      } else {
        this.props.dispatch({ type: 'RELOAD_PRODUCTS', dispatch: this.props.dispatch });
      }
    }
  }

  loadCategories() {
    WemeikAPI.categories.index(this.props.http, this.props.companyId)
      .then((categories) => {
        this.props.dispatch({ type: 'SET_CATEGORIES', categories: categories });
        if (categories && categories.length > 0) {
          this.props.dispatch({ type: 'SET_CATEGORY', categoryIndex: 0, dispatch: this.props.dispatch });
        }
      })
      .catch((error) => {
        console.error('Error getting categories: ', error);
        this.props.dispatch({ type: 'HIDE_PRELOADER' });
      });
  }

  selectProduct(product) {
    this.props.history.push(`/product/${product.id}`);
  }

  render() {
    if (!this.props.products || this.props.products.length < 1) {
      return (
        <div className="text-center p-2">
          <span className="material-icons text-dark" style={{fontSize: '70px'}}>cloud_off</span>
          <Headline4>No se han podido encontrar productos.</Headline4>
          <Body1>Por verifique su conexión a internet o intente seleccionando otra categoría.</Body1>
        </div>
      )
    }

    let currentCategory = null
    if (this.props.categories !== undefined && this.props.categories.length > 0 && this.props.selectedCategory >= 0) {
      currentCategory = this.props.categories[this.props.selectedCategory].name.toLowerCase()
    }

    return (
      <div>
        <Headline3 className="d-none d-md-block mt-1 mb-3 text-capitalize">{currentCategory}</Headline3>
        <div className="product-list" role="list">
          {
            this.props.products.map((p, i) => {
              return (
                <Product key={`${i}-${p.name}`} image={p.image_url} name={p.name} description={p.description} price={p.price} presentation={p.presentation} onClick={() => this.selectProduct(p)} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchTerm: state.main.searchTerm,
  categories: state.main.categories,
  selectedBranch: state.main.selectedBranch,
  selectedCategory: state.main.selectedCategory,
  products: state.main.products,
  http: state.main.$http,
  companyId: state.main.companyId
});
export default connect(mapStateToProps)(withRouter(ProductList));
