import React, { useEffect, useRef } from 'react'
import { useNearScreen } from '../../hooks/useNearScreen'
import '../../styles/animations.css'
const notFound = require(`../../assets/images/no-found_${process.env.REACT_APP_NO_FOUND_IMAGE}.png`)

const ImageWrapper = (props) => {
  const [show, element] = useNearScreen()

  return <figure className={props.className} ref={element}>{show && (<SecureImage {...props} />)}</figure>
}

const SecureImage = (props) => {
  const img = useRef(null)

  useEffect(() => {
    if ((!props.src || props.src.length <= 3) && img.current) {
      img.current.src = notFound
    }
  }, [props.src])

  const errorImage = (e) => {
    e.target.src = notFound
  }

  if (props.src === null) {
    return <img src={notFound} alt={props.alt} className={props.className} ref={img} />
  }

  return (
    <img src={props.src} alt={props.alt} className={`animation-fade-in ${props.className}`} onError={errorImage} ref={img} />
  )
}

export default ImageWrapper
