import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ApplicationLayout from '../components/Layouts/Application.js'
import ProductList from '../components/Products/List.js'
import Sidebar from '../components/Layouts/Sidebar.js'
import Head from '../components/Common/Head'
import Fab from '@material/react-fab'
import MaterialIcon from '@material/react-material-icon'
import { Link } from 'react-router-dom'
import { setDefaultPlace } from '../redux/functions'

const Home = ({ branches, company, dispatch }) => {
  useEffect(() => {
    setDefaultPlace(dispatch, branches)
  }, [branches, dispatch])

  return (
    <ApplicationLayout>
      <Head title='APP' />
      <div className="home-grid">
        <Sidebar />
        <ProductList />
      </div>
      {company.active_reservations &&
        <Link className="fab-action" to='reservation'>
          <Fab icon={<MaterialIcon icon='deck' />} title="Hacer una Reserva" textLabel="Reservar" />
        </Link>}
    </ApplicationLayout>
  )
}

const mapStateToProps = (state) => ({
  company: state.main.company,
  branches: state.main.branches
})
export default connect(mapStateToProps)(Home)
